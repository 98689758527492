import $ from 'jquery';

$('input.js-tags').selectize({
    load: function(query, callback) {
        if (!query.length) return callback();
        $.ajax({
            url: '/api/tags/search',
            type: 'POST',
            data: JSON.stringify({query}),
            error: function() {
                callback();
            },
            success: function(res) {
                console.log(res);
                callback(res);
            }
        });
    },
    plugins: ['remove_button', 'restore_on_backspace'],
    sortField: 'label',
    valueField: 'id',
    labelField: 'label',
    searchField: 'label',
});
