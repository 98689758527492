import $ from 'jquery';

$('.js-idea-vote-button').click(function () {
    let button = $(this);

    // disable button
    let parent = $(button.parent());
    if (!parent.hasClass('disabled')) {
        parent.addClass('disabled');
    }

    if (!button.hasClass('idea-vote-button-voted')) {
        // vote
        ideaVote(button);
    } else {
        // remove vote
        ideaRemoveVote(button);
    }
});

function ideaVote(button) {
    let ideaId = button.data('idea-id');
    let url = ideaVoteVoteUrl.replace(':ideaId:', ideaId);

    $.ajax(url, {
        context: button,
        method: 'POST',
    }).done(function () {
        button.addClass('idea-vote-button-voted');
        button.removeClass('idea-vote-button-not-voted');

        let span = button.children('span')[0];
        span.innerText = parseInt(span.innerText) + 1;

        enableButton(this);
    });
}

function ideaRemoveVote(button) {
    let ideaId = button.data('idea-id');
    let url = ideaVoteRemoveVoteUrl.replace(':ideaId:', ideaId);

    $.ajax(url, {
        context: button,
        method: 'POST',
    }).done(function () {
        button.addClass('idea-vote-button-not-voted');
        button.removeClass('idea-vote-button-voted');

        let span = button.children('span')[0];
        span.innerText = parseInt(span.innerText) - 1;

        enableButton(this);
    });
}

function enableButton(button) {
    let parent = $(button.parent());
    if (parent.hasClass('disabled')) {
        parent.removeClass('disabled');
    }
}
